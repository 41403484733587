import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import {
  circleSmall,
  waveHorizontalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  background-size: 160px 17px, 160px 17px, 17px 17px, 17px 47px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${circleSmall}), url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 320px) top 150px,
    left calc(${theme.container.background} - 87px) top 43%,
    left calc(${theme.container.background} - 127px) bottom 18%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 30px;
    min-height: 0;
  }
`;

const StyledRow = styled(Row)`
  align-items: flex-start;
  position: relative;

  ${[theme.media.maxM]} {
    display: block;
  }
`;

const Col6 = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
`;

const Spacer = styled.div`
  height: 30px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-top: 1px solid #ddd;
`;

class JobsPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.jobs.title" }) + " | Eledio.com"
          }
          meta={[
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.jobs.title" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Content>
            <Container>
              <StyledRow spacing={15}>
                <Col6>
                  <h1>
                    <FormattedMessage id="pages.jobs.title" />
                  </h1>
                  <ul>
                    <li>
                      <a href="#technical-product-manager">
                        Technical Product Manager – Smart Pool
                      </a>
                    </li>
                    <li>
                      <a href="#application-product-owner">
                        Application Product Owner – IoT Applications
                      </a>
                    </li>
                    <li>
                      <a href="#senior-javascript-developer">
                        Senior JavaScript Developer, IoT – React.JS
                      </a>
                    </li>
                  </ul>
                  <Spacer />
                  <>
                    <h3 id="technical-product-manager">
                      Technical Product Manager – Smart Pool
                    </h3>

                    <h4>About the Job</h4>
                    <p>
                      Eledio Poolmatix is Europe’s leading smart pool system,
                      bringing high-tech and ultimate comfort to backyards
                      across Europe. Our solution is redefining pool
                      installation and care, making it simpler, smarter, and
                      more efficient.
                    </p>
                    <p>
                      We are searching for an experienced Product Manager who
                      can elevate our product, boost user satisfaction, and
                      drive market expansion. If you are ready to make waves in
                      the smart pool industry, and become a subject matter
                      expert in the area, we want to hear from you.
                    </p>

                    <h4>Your Mission</h4>
                    <p>
                      As the Product Manager, you will take charge of both the
                      technical and customer-facing elements of Poolmatix,
                      ensuring our product stays ahead in a very competitive
                      market.
                    </p>

                    <h4>What You Will Do</h4>
                    <ul>
                      <li>
                        <strong>Drive Product Development:</strong> Oversee the
                        full product lifecycle, from core technical
                        functionalities to user-centric applications. Test the
                        product in the lab. Gather insights from our vast amount
                        of data and from users and partners to refine the
                        functionality and or offerings. Manage the development
                        pipeline in collaboration with our tech team.
                      </li>
                      <li>
                        <strong>Knowledge & Documentation Guru:</strong> Build
                        and maintain a dynamic technical knowledge base and
                        interactive documentation. Help shape and train our
                        AI-powered customer support and engagement system to
                        ensure every user gets the most out of Poolmatix.
                      </li>
                      <li>
                        <strong>Champion for Key Partners:</strong> Be the go-to
                        technical and business advisor for our top partners in
                        Europe. Work with them to deliver the highest quality
                        product offering and uncover new revenue streams and
                        market growth opportunities.
                      </li>
                    </ul>

                    <h4>Who You Are</h4>
                    <p>
                      Understanding a smart pool system requires a deep
                      knowledge of a broad range of areas. It combines water
                      chemistry, thermal physics, electronics, energy
                      management, and physical installation. Yet, the
                      interaction must be simple for the user. We’re looking for
                      someone who can master the deep complexities while keeping
                      the user experience as the top priority.
                    </p>

                    <h4>Your Background</h4>
                    <ul>
                      <li>
                        <strong>Education:</strong> BSc. or MSc. in a technical
                        field.
                      </li>
                      <li>
                        <strong>Experience:</strong> Proven background in
                        technical product management, especially in digital
                        product development.
                      </li>
                      <li>
                        <strong>Languages:</strong> Fluent in English;
                        proficiency in German, Czech, or Slovak is a big plus.
                      </li>
                      <li>
                        <strong>Skills:</strong> Exceptional communicator with a
                        talent for simplifying the complex, both in writing and
                        verbally.
                      </li>
                    </ul>

                    <p>
                      Please contact David Grundel,{" "}
                      <a href="mailto:david.grundel@eledio.com">
                        david.grundel@eledio.com
                      </a>
                      , +420602343440
                    </p>
                  </>
                  <Spacer />
                  <>
                    <h3 id="application-product-owner">
                      Application Product Owner – IoT Applications
                    </h3>

                    <h4>About the Job</h4>
                    <p>
                      Eledio develops and markets advanced IoT systems, with its
                      mobile and web applications at the core of the user
                      experience. Through these applications, users engage
                      directly with our products. We strive to provide the best
                      possible user experience.
                    </p>
                    <p>
                      We are looking for an experienced Application Product
                      Owner to enhance customer satisfaction and develop new
                      ways for users to connect with their smart things. If you
                      are interested in IoT and in building user-friendly
                      digital applications, we would like to talk to you.
                    </p>

                    <h4>Your Mission</h4>
                    <p>
                      As our Application Product Owner, you will manage all
                      aspects of the application development process, ensuring
                      an outstanding customer experience throughout the entire
                      user journey.
                    </p>

                    <h4>What You Will Do</h4>
                    <ul>
                      <li>
                        <strong>Application Development Oversight:</strong> Lead
                        the development lifecycle, from roadmap planning and
                        defining user requirements through UX/UI design and
                        development to the development and final user testing.
                      </li>
                      <li>
                        <strong>Content Management:</strong> Establish and
                        manage a single point of truth for application content.
                        Implement consistent content standards and oversee the
                        localization process.
                      </li>
                      <li>
                        <strong>Team Coordination:</strong> Act as the central
                        point of contact between developers, UI designers, and
                        content creators. Facilitate user testing, gather
                        feedback, and set development priorities to ensure our
                        applications remain focused on the user.
                      </li>
                    </ul>

                    <h4>Who You Are</h4>
                    <p>
                      Creating a strong digital experience requires an
                      understanding of UX/UI design, empathy for customer needs,
                      and good knowledge of the application development process.
                      We are looking for someone who has these skills and a keen
                      attention to user experience details
                    </p>

                    <h4>Your Background</h4>
                    <ul>
                      <li>
                        <strong>Education:</strong> BSc. or MSc., preferably in
                        a technical field.
                      </li>
                      <li>
                        <strong>Experience:</strong> Demonstrated experience in
                        application product ownership or management, with a
                        solid grasp of UX/UI and application development trends.
                      </li>
                      <li>
                        <strong>Languages:</strong> Fluent in English;
                        proficiency in German, Czech, or Slovak is a plus.
                      </li>
                      <li>
                        <strong>Skills:</strong> Strong empathy for users and a
                        talent for turning simple into complex.
                      </li>
                    </ul>
                    <p>
                      Please contact David Grundel,{" "}
                      <a href="mailto:david.grundel@eledio.com">
                        david.grundel@eledio.com
                      </a>
                      , +420602343440
                    </p>
                  </>
                  <Spacer />
                  <>
                    <h3 id="senior-javascript-developer">
                      Senior JavaScript Developer, IoT – React.JS
                    </h3>

                    <h4>About the Job</h4>
                    <p>
                      Eledio focuses on IoT innovation, creating advanced smart
                      systems that enhance users' experiences through intuitive
                      mobile and web applications. As we continue to scale, we
                      are committed to delivering world-class digital products
                      that seamlessly connect users with their smart devices.
                    </p>
                    <p>
                      We’re looking for an experienced Senior JavaScript
                      Developer with expertise in React.JS to join our team. If
                      you’re passionate about IoT and excited about creating
                      top-tier digital experiences, we would love to connect
                      with you.
                    </p>

                    <h4>Your Mission</h4>
                    <p>
                      As our Senior JavaScript Developer, you will lead the
                      development of both new and existing applications. You
                      will collaborate closely with team members across design,
                      integration, and testing to deliver engaging,
                      high-performance applications for both end-users and
                      internal stakeholders.
                    </p>

                    <h4>What You Will Do</h4>
                    <ul>
                      <li>
                        <strong>
                          Lead End-User IoT Application Development
                        </strong>{" "}
                        Design and implement applications that allow users to
                        interact directly with their IoT systems, providing an
                        outstanding user experience.
                      </li>
                      <li>
                        <strong>Enhance Customer Engagement Platforms:</strong>{" "}
                        Build interactive applications that showcase the Eledio
                        product suite and support customers throughout their
                        journey.
                      </li>
                      <li>
                        <strong>Refactor Legacy Applications:</strong> Modernise
                        and refactor legacy applications, introducing
                        cutting-edge technologies and new UI design to optimise
                        performance and usability.
                      </li>
                    </ul>

                    <h4>Who You Are</h4>
                    <p>
                      You are a senior developer with a passion for clean,
                      efficient code and an eye for detail. You enjoy tackling
                      challenges independently while also thriving in a
                      collaborative team environment. Your approach is
                      user-centric, and you’re driven to build applications that
                      prioritise performance, scalability, and ease of use.
                    </p>

                    <h4>Your Background</h4>
                    <ul>
                      <li>
                        <strong>Education:</strong> BSc or MSc in Computer
                        Science, Software Engineering, or a related technical
                        field (preferred).
                      </li>
                      <li>
                        <strong>Technical Expertise:</strong> Strong expertise
                        in <strong>React.JS</strong> and{" "}
                        <strong>TypeScript</strong>; familiarity with other
                        JavaScript frameworks and libraries is a bonus.
                      </li>
                      <li>
                        <strong>Experience:</strong> 6+ years in application
                        development, with proven experience in software
                        architecture, design, and agile development.
                      </li>
                      <li>
                        <strong>Languages:</strong> Good English; proficiency in
                        Czech, or Slovak is an advantage.
                      </li>
                      <li>
                        <strong>Key Skills:</strong> High degree of
                        independence, ability to translate complex requirements
                        into clean, scalable code, and an innate drive to
                        elevate user experience.
                      </li>
                    </ul>
                    <p>
                      Please contact David Grundel,{" "}
                      <a href="mailto:david.grundel@eledio.com">
                        david.grundel@eledio.com
                      </a>
                      , +420602343440
                    </p>
                  </>
                </Col6>
              </StyledRow>
            </Container>
          </Content>
        </Layout>
      </>
    );
  }
}

export default injectIntl(JobsPage);
